import LoadingDispene from "../components/LoadingDispene/LoadingDispene.component"
import MachineName from "../components/MachineName/MachineName.component"

const DispensingPage = () => {

    return (
        <>
            <MachineName />
            <LoadingDispene />
        </>
    )

}

export default DispensingPage
import { useLocation } from "react-router-dom";
import MachineName from "../components/MachineName/MachineName.component"
import SelectionData from "../components/SelectionData/SelectionData.component"
import UserData from "../components/UserData/UserData.component"

const SelectPage = () => {

    const location = useLocation();

    const getQueryParams = (query: any) => {
        return new URLSearchParams(query);
    };

    const queryParams = getQueryParams(location.search);
    const type = queryParams.get('type')
    const size = queryParams.get('size');


    return (
        <>
            <MachineName />
            <UserData />
            <SelectionData type={type} size={size} />
        </>
    )

}

export default SelectPage
import { Button } from 'react-bootstrap'
import SelectionButtons from '../SelectionButtons/SelectionButtons.component'
import './SelectionData.css'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const SelectionData = (props: { type: string | null, size: string | null }) => {

    const navigate = useNavigate()


    const getProductType = () => {
        let type = 1
        try {
            if (props.type === null) throw new Error('Type is null')
            type = parseInt(props.type)
        } catch (e: any) {
            type = 1
        }
        return type
    }

    const getProductSize = () => {
        let size = 1
        try {
            if (props.size === null) throw new Error('Size is null')
            size = parseInt(props.size)
        } catch (e: any) {
            size = 1
        }
        return size
    }

    const [typeSelected, setTypeSelected] = useState(getProductType())
    const [sizeSelected, setSizeSelected] = useState(getProductSize())

    return (
        <section className='selectionDataContainer d-flex flex-column mx-2 mt-2 p-2 text-start rounded-1'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <SelectionButtons type={1} selected={typeSelected} onChange={(n: number) => setTypeSelected(n)} />
            </div>
            <div className='h-100 mt-2 d-flex flex-column align-items-center'>
                <SelectionButtons type={2} selected={sizeSelected} onChange={(n: number) => setSizeSelected(n)} />
            </div>
            <div className='d-flex flex-row justify-content-center mb-4'>
                <Button className='btnContinue' onClick={() => navigate('/confirmation?type=' + typeSelected + '&size=' + sizeSelected)} variant='success'>CONTINUAR </Button>
            </div>
        </section>
    )

}

export default SelectionData
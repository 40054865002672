import './LoginForm.css'
import CompanyLogo from './../../utils/resources/logoCorsa.svg'
import { Button, Container, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


const LoginForm = () => {


    const navigate = useNavigate()

    const onLoginClick = (e: any) => {
        e.preventDefault()
        navigate('/select')
    }

    return (
        <Container fluid className="loginFormContainer d-flex flex-column align-items-center justify-content-center">
            <img src={CompanyLogo} alt="Corsa Logo" className="mb-4" style={{ width: 250 }} />
            <Form className="formContainer mt-3 text-center" onSubmit={onLoginClick}>
                <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="Email" />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Control type="password" placeholder="Contraseña" />
                </Form.Group>
                <Button variant="primary" type="button" className="w-100 mb-2">Iniciar sesión</Button>
                <Form.Text>¿Has olvidado la contraseña?</Form.Text>
                <hr />
                <Button variant="dark" type="button" className="w-100 mt-3">Registrate</Button>
                <Button variant="success" type="submit" className="btnWithOutSingiUp w-100">Usar sin registro</Button>

            </Form>
        </Container>
    )
}

export default LoginForm
import './UserData.css'

const UserData = () => {
    return (
        <section className='userDataContainer mx-2 mt-2 p-2 text-center rounded-1'>
            <span>Bienvenido Javier!</span>
            <div className='mt-2 row text-center'>
                <div className='col'>
                    <div className='row dataLabel'><span>2,40</span></div>
                    <div className='row textLabel'><span>Litros consumidos hoy</span></div>
                </div>
                <div className='col'>
                    <div className='row dataLabel'><span>153,45</span></div>
                    <div className='row textLabel'><span>Kg plástico ahorrados</span></div>
                </div>
            </div>
        </section>
    )

}

export default UserData
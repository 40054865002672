import './RestrictData.css'

const RestrictData = () => {
    return (
        <section className='restrictDataContainer mx-2 mt-2 p-2 text-center rounded-1'>
            <div className='row text-center'>
                <div className='col'>
                    <div className='row dataLabel'><span>3,10 / 5,00</span></div>
                    <div className='row textLabel'><span>LITROS DISPONIBLES HOY</span></div>
                </div>
            </div>
        </section>
    )

}

export default RestrictData
import './MachineName.css'

const MachineName = () => {
    return (
        <section className='machineNameContainer mx-2 mt-2 py-2 text-center rounded-1'>
            <span>DISPENSADOR 354 - HOSPITAL CLÍNIC</span>
        </section>
    )

}

export default MachineName

import QrReader from "../components/QRReader/qrReader.component"


const ScanPage = () => {

    return (
        <QrReader />
    )

}

export default ScanPage
import { Outlet, useNavigate } from 'react-router-dom'
import './TopBar.css'
import CompanyLogo from './../../utils/resources/logoCorsaWhite.png'

const TopBar = () => {

    const navigate = useNavigate()


    return (
        <>
            <header className='topBar'>
                <img className='logo' src={CompanyLogo} height={25} />
                <i onClick={() => navigate('/login')} className="icon bi bi-person-circle"></i>
            </header>
            <Outlet />
        </>
    )
}

export default TopBar
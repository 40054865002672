import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SelectPage from './pages/SelectPage';
import TopBar from './components/TopBar/TopBar.component';
import ConfirmationPage from './pages/ConfirmationPage';
import DispensingPage from './pages/DispensingPage';
import ScanPage from './pages/ScanPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<TopBar />} >
          <Route path="select" element={<SelectPage />} />
          <Route path="confirmation" element={<ConfirmationPage />} />
          <Route path="dispensing" element={<DispensingPage />} />
          <Route path="scan" element={<ScanPage />} />
          <Route path="*" element={<Navigate to={'/login'} />} />
        </Route>


      </Routes>
    </BrowserRouter >
  );
}

export default App;

import './SelectionButtons.css'
import TypeLogo from './../../utils/resources/gota.png'
import TypeLogoBlue from './../../utils/resources/gotaBlava.png'
import TypeIceLogo from './../../utils/resources/gotaHielo.png'
import TypeIceLogoBlue from './../../utils/resources/gotaHieloBlava.png'
import TypeBubblesLogo from './../../utils/resources/gotaBurbujas.png'
import TypeBubblesLogoBlue from './../../utils/resources/gotaBurbujasBlava.png'

const SelectionButtons = (props: { type: number, selected: number, onChange: (n: number) => void }) => {

    return (
        <div>
            {props.type === 1 ? <span>TIPO DE AGUA:</span> : <span>TAMAÑO DE BOTELLA:</span>}
            <div className='selectionButtonsContainer py-2 px-3 rounded-1 d-flex justify-content-between'>
                <div className={`btnSelection ${props.selected === 1 ? 'btnSelection-selected' : ''} rounded-1 p-1 d-flex flex-column justify-content-center align-items-center`} onClick={() => props.onChange(1)}>
                    {props.type === 1 && <img src={props.selected === 1 ? TypeLogo : TypeLogoBlue} height={50} width={50} />}
                    {props.type === 1 ? <span>AMBIENTE</span> : <span className='selectSizeText'>330 ml</span>}
                </div>
                <div className={`btnSelection ${props.selected === 2 ? 'btnSelection-selected' : ''} rounded-1 p-1 d-flex flex-column justify-content-center align-items-center`} onClick={() => props.onChange(2)}>
                    {props.type === 1 && <img src={props.selected === 2 ? TypeIceLogo : TypeIceLogoBlue} height={50} width={50} />}
                    {props.type === 1 ? <span>FRÍA</span> : <span className='selectSizeText'>500 ml</span>}
                </div>
                <div className={`btnSelection ${props.selected === 3 ? 'btnSelection-selected' : ''} rounded-1 p-1 d-flex flex-column justify-content-center align-items-center`} onClick={() => props.onChange(3)}>
                    {props.type === 1 && <img src={props.selected === 3 ? TypeBubblesLogo : TypeBubblesLogoBlue} height={50} width={50} />}
                    {props.type === 1 ? <span>GAS</span> : <span className='selectSizeText'>1000 ml</span>}
                </div>
            </div>
        </div>
    )
}

export default SelectionButtons
import { Image } from 'react-bootstrap'
import './GlassAnimation.css'
import FillingGlass from './../../utils/resources/FillingGlass.gif'

const GlassAnimation = () => {
    return (
        <div className='glassContainer rounded-2 mt-3 mx-auto'>
            <Image src={FillingGlass} width='100%' />
        </div>
    )
}

export default GlassAnimation
import LoginForm from "../components/LoginForm/LoginForm.component"


const LoginPage = () => {

    return (
        <LoginForm />
    )

}

export default LoginPage
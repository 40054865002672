import { Image, Modal } from 'react-bootstrap'
import './PaymentModal.css'
import { useEffect, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const PaymentModal = (props: { showModal: boolean, onClose: () => void, onPay: () => void }) => {

    const [showLoading, setShowLoading] = useState(false)

    const renderTime = (props: { remainingTime: number }) => {
        return (
            <div>
                <span>Procesando pago...</span>
            </div>
        );
    };



    return (
        <Modal
            show={props.showModal}
            onHide={props.onClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton />
            <Modal.Body className='modalContainer'>
                {showLoading ?
                    <div className='h-100 d-flex justify-content-center align-items-center'>
                        <CountdownCircleTimer
                            isPlaying
                            duration={5}
                            colors={["#257DE1", '#257DE1', '#257DE1', '#257DE1']}
                            colorsTime={[10, 6, 3, 0]}
                            onComplete={() => props.onPay()}
                        >
                            {renderTime}
                        </CountdownCircleTimer></div> :
                    <div className='imgContainer' onClick={() => setShowLoading(true)}><Image src='https://b.stripecdn.com/docs-statics-srv/assets/c63e01cc65f29058b5709a0b8bcabf8b.svg' width='100%' /></div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default PaymentModal
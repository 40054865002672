import { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

const QrReader = () => {
    const videoRef = useRef(null);
    const [qrCode, setQrCode] = useState<QrScanner.ScanResult | null>(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        const qrScanner = new QrScanner(videoElement, result => {
            console.log('decoded qr code:', result);
            setQrCode(result);
        }, {
            onDecodeError: error => {
                console.error(error);
            },
            highlightScanRegion: true,
            highlightCodeOutline: true,
        });

        qrScanner.start();

        return () => {
            qrScanner.stop();
        };
    }, []);

    return (
        <div>
            <video ref={videoRef} style={{ width: '100%' }}></video>
            {/*qrCode && <p>QR Code: {qrCode}</p>*/}
        </div>
    );
};

export default QrReader;

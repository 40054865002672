import { useLocation } from "react-router-dom";
import MachineName from "../components/MachineName/MachineName.component"
import RestrictData from "../components/RestrictData/RestrictData.component"
import SummaryData from "../components/SummaryData/SummaryData.component"

const ConfirmationPage = () => {


    const location = useLocation();

    const getQueryParams = (query: any) => {
        return new URLSearchParams(query);
    };

    const queryParams = getQueryParams(location.search);
    const type = queryParams.get('type')
    const size = queryParams.get('size');

    return (
        <>
            <MachineName />
            <RestrictData />
            <SummaryData type={type} size={size} />
        </>
    )
}

export default ConfirmationPage

import './LoadingDispene.css'
import { useNavigate } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useEffect, useState } from 'react'
import GlassAnimation from '../GlassAnimation/GlassAnimation.component'
import { Button } from 'react-bootstrap'

const LoadingDispene = () => {

    const navigate = useNavigate()
    const [showFinalText, setShowFinalText] = useState(false)

    useEffect(() => {
        if (showFinalText) {
            setTimeout(() => navigate('/select'), 2000)
        }

    }, [showFinalText])

    const renderTime = (props: { remainingTime: number }) => {
        return (
            <div>
                <div className="timerText">{props.remainingTime}</div>
            </div>
        );
    };


    return (
        <section className='loadingDispeneContainer d-flex flex-column mx-2 mt-2 p-2 text-start rounded-1'>
            <section className='mt-5 d-flex flex-column justify-content-center align-items-center'>
                <CountdownCircleTimer
                    isPlaying
                    duration={10}
                    colors={["#257DE1", '#257DE1', '#257DE1', '#257DE1']}
                    colorsTime={[10, 6, 3, 0]}
                    onComplete={() => (setShowFinalText(true))}
                >
                    {renderTime}
                </CountdownCircleTimer>
            </section>
            <div>
                <GlassAnimation />
            </div>
            <div className='h-100 mt-2 d-flex flex-row justify-content-center'>
                <span>{showFinalText ? `Disfrute de su dia!` : `Espere que termine la operación...`}</span>
            </div>
            {/*<div className='d-flex flex-row justify-content-center mb-4'>
                <Button className='cancelButton' variant='warning'>Cancelar</Button>
    </div>*/}
        </section>
    )

}

export default LoadingDispene
import { Button } from 'react-bootstrap'
import TypeLogoBlue from './../../utils/resources/gotaBlava.png'
import TypeIceLogoBlue from './../../utils/resources/gotaHieloBlava.png'
import TypeBubblesLogoBlue from './../../utils/resources/gotaBurbujasBlava.png'
import Bottle from './../../utils/resources/bottle.png'
import './SummaryData.css'
import { useNavigate } from 'react-router-dom'
import PaymentModal from '../PaymentModal/PaymentModal.component'
import { useState } from 'react'

const SummaryData = (props: { type: string | null, size: string | null }) => {

    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const getProductType = () => {
        let type = 1
        try {
            if (props.type === null) throw new Error('Type is null')
            type = parseInt(props.type)
        } catch (e: any) {
            type = 1
        }

        switch (type) {
            case 1:
                return (
                    <>
                        <img src={TypeLogoBlue} height={65} width={65} />
                        <span>AMBIENTE</span>
                    </>
                )

            case 2:
                return (
                    <>
                        <img src={TypeIceLogoBlue} height={65} width={65} />
                        <span>FRÍA</span>
                    </>
                )

            case 3:
                return (
                    <>
                        <img src={TypeBubblesLogoBlue} height={65} width={65} />
                        <span>GAS</span>
                    </>
                )

            default:
                return (
                    <>
                        <img src={TypeLogoBlue} height={65} width={65} />
                        <span>AMBIENTE</span>
                    </>
                )
        }
    }

    const getProductSize = () => {
        let size = 1
        try {
            if (props.size === null) throw new Error('Size is null')
            size = parseInt(props.size)
        } catch (e: any) {
            size = 1
        }

        switch (size) {
            case 1:
                return (<span className='sizeText'>330 ml</span>)

            case 2:
                return (<span className='sizeText'>500 ml</span>)

            case 3:
                return (<span className='sizeText'>1000 ml</span>)

            default:
                return (<span className='sizeText'>500 ml</span>)
        }
    }

    const onPay = () => {
        setShowModal(false)
        navigate('/dispensing')
    }

    const onClose = () => {
        setShowModal(false)
    }


    return (
        <>
            <PaymentModal showModal={showModal} onClose={onClose} onPay={onPay} />
            <section className='summaryDataContainer d-flex flex-column mx-2 mt-2 p-2 text-start text-lg-center rounded-1'>
                <span>TU SELECCIÓN:</span>
                <section className='mt-2 d-flex flex-row justify-content-center'>
                    <div className={`productSummary mx-3 rounded-1 p-1 d-flex flex-column justify-content-center align-items-center`} >
                        {getProductType()}
                    </div>
                    <div className={`productSummary mx-3 rounded-1 p-1 d-flex flex-column justify-content-center align-items-center`} >
                        {getProductSize()}
                    </div>
                </section>
                <section className='atencionContainer mt-3 mx-auto p-1 rounded-1 d-flex flex-column text-center'>
                    <span>ATENCIÓN!</span>
                    <span>COLOCAR LA BOTELLA ANTES DE COMENZAR LA OPERACIÓN</span>
                </section>
                <section className='mt-2 mb-2 h-100 d-flex justify-content-center align-items-center'>
                    <img src={Bottle} className='imgBottle' />
                </section>
                <div className='d-flex flex-row justify-content-center mb-4'>
                    <Button className='backButton mx-1' onClick={() => navigate('/select?type=' + props.type + '&size=' + props.size)} variant='warning'><i className="bi bi-arrow-left-square"></i></Button>
                    <Button className='startButton mx-1' onClick={() => setShowModal(true)} variant='success'>COMENZAR</Button>
                </div>
            </section>
        </>
    )

}

export default SummaryData